import Vue from "vue";
import Vuex from "vuex";
import axios from 'axios';

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
      pusher_id: null,
      pusher_cluster: null,
      user: {
        token: null,
        id_client: null,
        id_usuario: null,
        usuario: null,
        is_active: null,
      },
      form: {
        production_planning: {
          container: "",
          container_id: 0,
          container_net_kilograms: ""
        },
        process_type: null,
        contents_in: [],
        contents_out: []
      }
  },
  getters: {
    totalInNet: (state) => {
      return state.form.contents_in.reduce((acc, item) => acc + parseFloat(item.net_kilograms), 0).toFixed(2);
    },
    totalExportable: (state) => {
      let value = state.form.contents_out
        .filter(item => item.exportable === true)
        .reduce((acc, item) => acc + parseFloat(item.net_kilograms), 0)
        .toFixed(2);
      return isNaN(value) ? 0.0 : value;
    },
    totalExportableQuantity: (state) => {
      let value = state.form.contents_out
        .filter(item => item.exportable === true)
        .reduce((acc, item) => acc + parseInt(item.quantity), 0)
        .toFixed(0);
      return isNaN(value) ? 0 : value;
    },
  },
  mutations: {
    setPusherId: (state, payload) => {
        state.pusher_id = payload[0];
        state.pusher_cluster = payload[1];
      },
    clearPusherId: state => {
        state.pusher_id = null;
        state.pusher_cluster = null;
      },
    handleLogin: (state, payload) => {
      state.user.token = payload.token;
      state.user.id_client = payload.id_client;
      state.user.id_usuario = payload.id_usuario;
      state.user.usuario = payload.usuario;
      state.user.is_active = payload.is_active;
      localStorage.setItem("token", payload.token);
      axios.defaults.headers.common["Authorization"] =
          "token " + payload.token;
    },
    handleLogout: (state) => {
      state.user.token = null;
      state.user.id_client = null;
      state.user.id_usuario = null;
      state.user.usuario = null;
      state.user.is_active = null;
      localStorage.removeItem("token");
    },
    updateProcess(state, payload) {
      state.form = payload;
    },
    addRowsIn(state, payload) {
      state.form.contents_in.unshift(payload);
    },
    addRowsOut(state, payload) {
      state.form.contents_out.unshift(payload);
    },
  },
  actions : {
    setPusherId: ({commit}, payload) => {
      commit('setPusherId', payload)
    },
    clearPusherId: ({commit}) => {
      commit('clearPusherId')
    },
    login: ({commit}, payload) => {
      commit('handleLogin', payload);
    },
    logout: ({commit}) => {
      commit('handleLogout');
    },
    updateProcess({ commit }, payload) {
      commit("updateProcess", payload);
    },
    addRowsIn: ({ commit }, payload) => {
      commit("addRowsIn", payload);
    },
    addRowsOut: ({ commit }, payload) => {
      commit("addRowsOut", payload);
    },
  },
});