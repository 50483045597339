<template>
  <div class="wraper">
    <b-row class="my-3">
      <b-col>
        <b-button @click.prevent="logout" variant="link" class="float-right"> <b-icon-box-arrow-left/> Salir</b-button>
        <h1>Procesos Abiertos</h1>
        <b-spinner v-if="loading"></b-spinner>
        <b-table v-if="!loading" striped hover :fields="fields" :items="items">
          <template #cell(actions)="row">
            <b-button size="sm" class="mr-1" @click.prevent="goToProcess(row.item)">
              Ver proceso
            </b-button>
          </template>
          <template #cell(start_time)="row">
            {{row.item.start_time | h_datetime}}
          </template>
          <template #cell(shift_supervisor)="row">
            {{row.item.shift_supervisor.first_name}} {{row.item.shift_supervisor.last_name}}
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment';

moment.locale("es");

export default {
  name: 'Home',
  components: {
  },
  data () {
    return {
      loading: false,
      counterInterval: null,
      fields: [
        { key: "client_customer.name", label: "Exportadora"},
        { key: "number", label: "Numero"},
        { key: "process_room.name", label: "Línea"},
        { key: "shift.name", label: "Turno"},
        { key: "shift_supervisor", label: "Responsable"},
        { key: "shift_supervisor.position", label: "Cargo"},
        { key: "start_time", label: "Inicio"},
        { key: "actions", label: " "},
      ],
      items: []
    }
  },
  created(){
    this.getProcessList();
  },
  mounted() {
    this.counterInterval = setInterval(() => {
      this.getProcessList();
    }, 1000*60);
  },
  destroyed() {
    clearInterval( this.counterInterval );
  },
  filters: {
    h_datetime (value) {
      return moment(value).format("DD-MM-yy HH:MM")
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.$router.push({name:"login"});
    },
    goToProcess(process) {
      this.$router.push({name:"process", params: { process: process } })
    },
    async getProcessList() {
      const thisV = this;
      thisV.loading = true;
      try {
        const response = await thisV.$axios({
          method: "GET",
          url: `api/v1/process/open_processes/`,
        });
        thisV.items = response.data;
        thisV.loading = false;
      } catch (error) {
        console.log(error);
        thisV.loading = false;
      }
    },

  }
}
</script>
