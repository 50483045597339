import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import { store } from './store/store'

Vue.config.productionTip = false
Vue.prototype.$axios = axios;
// axios.defaults.baseURL = "https://api.packapp.cl/";
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
const token = localStorage.getItem('token');
if (token) {
  axios.defaults.headers.common['Authorization'] = 'token ' + token
}

store.dispatch('setPusherId', [process.env.VUE_APP_PUSHER_ID, "us2"]);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
